import React from 'react';
import { reduxForm, } from 'redux-form';
import { Alert, Modal, Spinner, Table, Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Show } from 'Layout';
import { PageStatus } from 'enums';
import { AuthAPI, MasterDataAPI, SurveysAPI } from "../../API";
import Select from 'react-select';
import moment from "moment";
import Swal from 'sweetalert2';
export type FormValue = {
  name: string,
};

type State = {
  status: string,
  error: string | null,
  data: any,
  name: string,
};


function replacePlaceholderWithAnchor(text) {
  if (/<a\s+(?:[^>]*?\s+)?href=(?:'([^']*)'|"([^"]*)")/.test(text)) {
    return text;
  }
  const regex = /<([^>]+)>/;
  const match = text.match(regex);
  if (match) {
    const placeholder = match[0];
    const url = match[1];
    const anchorTag = `<a href='${url}' target='_blank' style='color: orange;'>${url}</a>`;
    return text.replace(placeholder, anchorTag);
  } else {
    return text;
  }
}

class Form extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      formType: "NONE",
      selectedPreScreenQueDet: {},
      error: null,
      options: [],
      blacklistSurvey: [],
      preScreenOptions: [],
      selectedPreScreenQues: [],
      data: '',
      name: "",
      company: "",
      description: "",
      "userLimitCommitted": 0,
      "userLimitCutoff": 0,
      "client": "",
      "ceggPoints": 0,
      "overquota": 0,
      "terminate": 0,
      "qualityterminate": 0,
      "surveyLength": 0,
      // "publishDate": "",
      publishDate: new Date().toISOString().split("T")[0],
      "expiryDate": "",
      "outlierCutoffTime": '',
      "costPerInterview": 0,
      "isActive": true,
      "companyLogo": "",
      "useUniqueLinks": false,
      "ipUnique": false,
      "surveyUrlIdentifier": "id",
      "url": "",
      "surveyType": "Open",
      "pointAllocationType": "Manual",
      disclaimer: 'Disclaimer: Dear Respondent, Thank you for your continued patronage. To serve you more Research Polls, we request that you always keep your profile up to date. Your valuable profile is a guiding light for the Online Market Research Industry, please visit <a href="/" target="_blank" style="color: orange;">indiapolls.com</a> to login and update your profile for a more rewarding journey ahead ! Thank you.',
      isPaused: false,
      countries: [],
      selectedCountryOption: null,
      country: '',
      description_one: '',
      description_two: '',
      description_three: '',
      description_four: '',
      colorCode: '',
      whatsapp: "N",
      email: "N",
      sms: "N",
      clientir: "",
      Newdateexpireset: "",
      // status: '',
      // data: null,
      userLimitCommitted_checkbox: '',
      IPollsQuotaLimit: 'N',
      preQuestionsYN: "N",
      localExpiryDate: props.expiryDate, // Store expiryDate locally
      details: [],
      submittedAnswerDets: [],
      answersFields: [],
      questionQuotaPoints: 0,
      quotaPointAnswer: "",
      pmName: "",
      validateStartEndIp: "Y",
      // validateVpn: "Y",
      validateCountry: "Y",
    };
  }

  componentDidMount() {
    if (!!this.props.id) {
      this.fetchDetails();
    } else {
      this.fetchList()
    }
  }

  fetchDetails() {
    Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => {
        if (!this.props.id) {
          return Promise.reject(new Error('Invalid ID'));
        }

        return SurveysAPI.getOne(this.props.id);
      })
      .then((respoData) => {
        let data = respoData.data;
        this.initializeValues(respoData);
        this.setState({
          data,
          status: PageStatus.Loaded,
        }, () => {
          this.fetchList()
          // this.fetchCountryList()
        });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  // ====================add functionallity status chnage ======
  handleClick = (status) => {
    console.log(status);
    this.setState({ status }, () => {
      if (this.state.data) {
        this.handleClickcheck(this.state.data);
      }
    });
  };

  handleClickcheck = (data) => {
    const modals = document.getElementsByClassName('fade modal show');
    const changeExpireDateInput = document.getElementById('expiryDate');

    if (!data || !data.expiryDate) {
      return; // Exit early if data or expiryDate is not available
    }

    // Get and parse the new expiry date from the input field
    const changeExpireDate = changeExpireDateInput ? new Date(changeExpireDateInput.value) : null;
    const currentDate = new Date();

    // Function to set zIndex
    const setModalZIndex = (zIndex) => {
      Array.from(modals).forEach(modal => {
        modal.style.zIndex = zIndex;
      });
    };

    if (changeExpireDate && changeExpireDate < currentDate) {
      setModalZIndex('0');
      this.setState({ surveyType: 'Hold' }, () => {
        Swal.fire({
          icon: 'warning',
          title: 'Expired',
          text: 'The expiry date is in the past. Please correct the date',
        }).then(() => {
          setModalZIndex(''); // Reset zIndex after Swal.fire closes
        });
      });
    } else {
      setModalZIndex(''); // Ensure zIndex is reset for non-expired cases
      this.setState({ surveyType: 'Open' });
    }
  };


  onChangeStatus = (id, status) => {
  };

  fetchCountryList(): Promise<void> {
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => MasterDataAPI.countryList('10'))
      .then((countries) => {
        const options = countries.map(country => ({
          label: country.name,
          value: country.name
        }));
        options.sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
        this.setState({ countries: options, status: PageStatus.Loaded });
        if (this.state.country) {
          const option = options.find(item => item.value === this.state.country);
          this.setState({ selectedCountryOption: option });
        } else {
          this.setState({ country: options[0].label, selectedCountryOption: options[0] });
        }
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }


  fetchList(): Promise<void> {
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => SurveysAPI.getAll(10000, 1, {}))
      .then((surveys) => {
        let responseData: any = surveys;
        const options = responseData.data.map(item => ({
          label: item.name,
          value: item.id
        }));
        this.setState({ options, status: PageStatus.Loaded });
        this.fetchCountryList();
        this.preScreenFetchList();
        if (this.state.data.surveyblacklistentities) {
          const selectedSurveyOptionss = this.state.data?.surveyblacklistentities.map((item: any) => options.filter(ele => ele.value == item.blacklistSurveyId)).flat();
          const blacklistSurvey = [];
          selectedSurveyOptionss.forEach(ele => blacklistSurvey.push(ele.value));
          if (selectedSurveyOptionss) {
            this.setState({
              blacklistSurvey,
              selectedSurveyOptionss
            })
          }
        }
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }

  // GET Pre Screening Questions List
  preScreenFetchList(): Promise<void> {
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => AuthAPI.getAllQuestions({}))
      .then((preQues) => {
        const preScreenOptions = preQues.map(item => ({
          label: item.question,
          value: item.id,
          type: item.type,
          question: item.question,
          answers: item.answer,
          questionType: item.questionType,
          selectedAnsArr: [],
          questionDisplayOrder: '',
        }));
        // let newArr = this.state.details.map(que => preScreenOptions.filter(selectedQue => selectedQue.value == que.id)).flat();
        let selectedPreScreenQues = this.state.details.map((ele) => {
          return {"answers": ele.answerId.map(ele=>ele.answer),
            "label": ele.question,
            "question": ele.question,
            "questionDisplayOrder": "",
            "questionType": ele.questionType,
            "selectedAnsArr": ele.answerId,
            "type": ele.type,
            "value": ele.id }
        });
        let submittedAnswerDets = [];
        selectedPreScreenQues.forEach((ele) => {
          ele.selectedAnsArr.forEach(selectedItem => {
            let answerExists = ele.answers.some(answer => JSON.stringify(answer) === JSON.stringify(selectedItem.answer) );
            if (!answerExists) { ele.answers.push(selectedItem.answer); }
          });
          this.state.details.forEach((newElem) => {
            if (ele.value == newElem.id) {
              ele.selectedAnsArr = newElem.answerId.filter(ele => ele.mark == "y").map((elem)=> {return {"answer":elem.answer, "mark": elem.mark , "questionQuotaPoints":elem.questionQuotaPoints ?? 0}});
              submittedAnswerDets.push(ele.value);
            }
          })
        });
        this.setState({ preScreenOptions, selectedPreScreenQues, status: PageStatus.Loaded, submittedAnswerDets });
      })
  }

  formValues() {
    return {
      name: this.state.name + " [" + this.state.terminate + " - " + this.state.ceggPoints + "]",
      company: this.state.company,
      description: this.state.description,
      "userLimitCommitted": this.state.userLimitCommitted,
      "userLimitCutoff": this.state.userLimitCutoff,
      "client": this.state.client,
      "ceggPoints": this.state.ceggPoints,
      "overquota": this.state.overquota,
      "terminate": this.state.terminate,
      "qualityterminate": this.state.qualityterminate,
      "surveyLength": this.state.surveyLength,
      "publishDate": this.state.publishDate,
      "expiryDate": this.state.expiryDate,
      "outlierCutoffTime": this.state.outlierCutoffTime,
      "costPerInterview": this.state.costPerInterview,
      "isActive": this.state.isActive,
      "companyLogo": this.state.companyLogo,
      "useUniqueLinks": this.state.useUniqueLinks,
      "ipUnique": this.state.ipUnique,
      "surveyUrlIdentifier": this.state.surveyUrlIdentifier,
      "url": this.state.url,
      blacklistSurvey: this.state.blacklistSurvey,
      "surveyType": this.state.surveyType,
      "pointAllocationType": this.state.pointAllocationType,
      minimumInterviewDuration: 20,
      isPaused: false,
      disclaimer: replacePlaceholderWithAnchor(this.state.disclaimer),
      description_one: this.state.description_one,
      description_two: this.state.description_two,
      description_three: this.state.description_three,
      description_four: this.state.description_four,
      country: this.state.country,
      colorcode: this.state.colorCode,
      sendServeyBy: this.state.sendServeyBy,
      whatsapp: this.state.whatsapp,
      email: this.state.email,
      sms: this.state.sms,
      clientir: this.state.clientir,
      IPollsQuotaLimit: this.state.IPollsQuotaLimit,
      preQuestionsYN: this.state.preQuestionsYN,
      validateStartEndIp: this.state.validateStartEndIp,
      // validateVpn: this.state.validateVpn,
      validateCountry: this.state.validateCountry,
      details: this.state.details,
      pmName: this.state.pmName,
    };
    this.handleClickcheck(this.state.data);
  }

  initializeValues(paramData) {
    let data = paramData.data;
    const formattedExpiryDate = moment(data.expiryDate).format("YYYY-MM-DD");
    this.setState({ Newdateexpireset: formattedExpiryDate });
    return this.setState({
      name: data.name.split(" [")[0],
      company: data.company,
      description: data.description,
      "userLimitCommitted": data.userLimitCommitted,
      "userLimitCutoff": data.userLimitCutoff,
      "client": data.client,
      "ceggPoints": data.ceggPoints,
      "overquota": data.overquota,
      "terminate": data.terminate,
      "qualityterminate": data.qualityterminate,
      "surveyLength": data.surveyLength,
      "publishDate": moment(data.publishDate).format("YYYY-MM-DD"),
      "expiryDate": moment(data.expiryDate).format("YYYY-MM-DD"),
      "outlierCutoffTime": data.outlierCutoffTime,
      "costPerInterview": data.costPerInterview,
      "isActive": data.isActive,
      "companyLogo": data.companyLogo,
      "useUniqueLinks": data.useUniqueLinks,
      "ipUnique": data.ipUnique,
      "surveyUrlIdentifier": data.surveyUrlIdentifier,
      "url": data.url,
      "surveyType": data.surveyType,
      "pointAllocationType": data.pointAllocationType,
      minimumInterviewDuration: data.minimumInterviewDuration,
      isPaused: data.isPaused,
      disclaimer: data.disclaimer,
      description_one: data.description_one,
      description_two: data.description_two,
      description_three: data.description_three,
      description_four: data.description_four,
      country: data.country,
      colorCode: data.colorcode,
      whatsapp: data.whatsapp,
      email: data.email,
      sms: data.sms,
      clientir: data.clientir,
      IPollsQuotaLimit: data.IPollsQuotaLimit,
      preQuestionsYN: data.preQuestionsYN,
      validateStartEndIp: data.validateStartEndIp ?? "N",
      // validateVpn: data.validateVpn ?? "N",
      validateCountry: data.validateCountry ?? "N",
      details: paramData.surveyquestion.length > 0 ? paramData.surveyquestion.map((option) => { return { "id": option.question_id, "question": option.question, "questionType": option.questionType, "type": option.type, "answerId": option.answerId, "questionDisplayOrder": option.questionDisplayOrder } }).sort((a, b) => { return a.questionDisplayOrder - b.questionDisplayOrder }) : [],
      pmName: data.pmName ?? "",
    });
  }
  onSubmit(evt) {
    if (this.state.details.length < 1 && this.state.preQuestionsYN == "Y") {
      alert(`You checked the Add pre-screening questions checkbox but didn't selected any question.`);
      return;
    }
    if (this.state.details.length > 0 ) {
      let emptyArr = [];
      this.state.details.forEach((ele) => {
        if (ele.answerId.length < 1) { emptyArr.push(ele.question); }
      })
      if(emptyArr.length != 0){ alert(`Answer is not selected for the question\n \n ${emptyArr[0]}`); return; }
    }
    if (this.state.selectedPreScreenQues.length > 0) {
      this.state.details.forEach((ele, indxx) => { ele.questionDisplayOrder = indxx; })
    }
    if (!this.props.id) {
      return this.create(evt);
    }
    return this.update(evt);
  }

  create(event) {
    const valuesIn = this.formValues();
    // console.log("create values in = ",valuesIn);
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => SurveysAPI.create(valuesIn))
      .then((data) => {
        this.props.onSubmit(data.Survey.id);
        return this.setState({ status: PageStatus.Submitted, submittedAnswerDets: [] });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  update(event) {
    const valuesIn = this.formValues();
    // console.log("update values in = ",valuesIn);
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => SurveysAPI.update(this.props.id, valuesIn))
      .then(() => {
        this.setState({ status: PageStatus.Submitted, submittedAnswerDets: [] });
        return this.props.onSubmit(this.props.id);
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  reset() {
    return this.setState({
      data: '',
      name: "",
      company: "",
      description: "",
      "userLimitCommitted": 0,
      "userLimitCutoff": 0,
      "client": "",
      "ceggPoints": 0,
      "surveyLength": 0,
      "publishDate": "",
      "expiryDate": "",
      "outlierCutoffTime": '',
      "costPerInterview": 0,
      "isActive": true,
      "companyLogo": "",
      "useUniqueLinks": false,
      "ipUnique": false,
      "surveyUrlIdentifier": "id",
      "url": "",
      "surveyType": "Open",
      "pointAllocationType": "Manual",
      isPaused: false,
      selectedCountryOption: null,
      country: '',
      description_one: '',
      description_two: '',
      description_three: '',
      description_four: '',
      colorCode: '',
      whatsapp: "N",
      email: "N",
      sms: "N",
      clientir: '',
      answersFields: [],
      pmName: "",
      validateStartEndIp: "Y",
      // validateVpn: "Y",
      validateCountry: "Y",
    });
  }

  handleChange = (selectedSurveyOptionss) => {
    this.setState({
      blacklistSurvey: selectedSurveyOptionss.map(option => option.value),
      selectedSurveyOptionss
    });
  };

  handlePreScreenQuestions = (selectedQues) => {
    this.setState((prevState) => {
      let selectedPreScreenQues = selectedQues;
      prevState.details.length < selectedPreScreenQues.length ? (selectedPreScreenQues[selectedPreScreenQues.length - 1].selectedAnsArr = []) : "";
      let newAnsArr = this.state.submittedAnswerDets;
      let submittedAnswerDets = newAnsArr.filter(ele => selectedPreScreenQues.some(element => element.value == ele));
      return {
        details: selectedPreScreenQues.map((option) => { return { "id": option.value, "question": option.question, "questionType":option.questionType, "type": option.type, "answerId": option.selectedAnsArr } }),
        selectedPreScreenQues,
        preQuestionsYN: selectedPreScreenQues.length > 0 ? "Y" : "N",
        submittedAnswerDets,
      }
    })
  }

  handleCountryChange = async (selectedCountryOption) => {
    this.setState({ country: selectedCountryOption.label, selectedCountryOption });
  };

  handleSendSurvey = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked ? "Y" : "N"
    });
  }


  handleChangePrescreen = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked ? "Y" : "N",
      submittedAnswerDets: [],
      details: [],
      selectedPreScreenQues: [],
    }, () => {checked ? this.preScreenFetchList() : null});
  }

  handleCheckboxValidation = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked ? "Y" : "N",
    });
  }

  check_date_past = (e) => {
    const expiryDate = e.target.value;
    const { publishDate } = this.state;
    if (publishDate && new Date(expiryDate) < new Date(publishDate)) {
      this.setModalZIndexNew('0');
      Swal.fire({
        icon: 'warning',
        title: 'Expired',
        text: 'The expiry date is in the past. Please correct the date',
      }).then(() => {
        this.setModalZIndexNew('');
        this.setState({ expiryDate: this.state.Newdateexpireset }, () => {
        });
      });
    } else {
      this.setState({ expiryDate });
      this.setModalZIndexNew('');
    }
  };

  setModalZIndexNew = (zIndex) => {
    const modals1 = document.getElementsByClassName('fade modal show');
    Array.from(modals1).forEach(modal => {
      modal.style.zIndex = zIndex;
    });
  };


  // ================================================
  handleUserLimitCommittedChange = (e) => {
    const isChecked = e.target.checked; // Use checked instead of value
    this.setState({
      userLimitCommitted_checkbox: isChecked, // Set to true or false
      IPollsQuotaLimit: isChecked ? 'Y' : 'N' // Update IPollsQuotaLimit based on checked state
    });
  };
  //====================================================
  updatePreScreenAnswer = async (wholeElem, indxx) => {
    this.setState({ formType: "UPDATE", selectedPreScreenQueDet: wholeElem });
  }

  onCloseModalFunc = () => {
    this.setState({ formType: "NONE" });
  }

  onCloseAddAnsModalFunc = () => {
    this.setState({ formType: "UPDATE" });
  }

  onFormUpdate = async (selectedElem) => {
    const allanswer = selectedElem.answers;
    const selectedanswer = selectedElem.selectedAnsArr;
    const markedAnswers = allanswer.map(ans => {
      const selected = selectedElem.questionType == "Range" ? selectedanswer.find(item => item.answer.fromValue == ans.fromValue && item.answer.toValue == ans.toValue) : selectedanswer.find(item => item.answer == ans); 
      return {
        answer: ans,
        mark: selected ? 'y' : 'n',
        questionQuotaPoints: selected ? parseInt(selected.questionQuotaPoints) : 0, 
      };
    });
    try {
      if (selectedElem.selectedAnsArr.length < 1) {
        alert("Please choose answer first");
        return;
      }
      this.setState({ status: PageStatus.Loading });
      this.setState((prevState) => {
        let newAnsArr = this.state.submittedAnswerDets;
        let submittedAnswerDets = newAnsArr.filter(ele => ele != selectedElem.value);
        submittedAnswerDets.push(selectedElem.value);
        let details = this.state.details;
        details.forEach((elem) => {
          if (elem.id == selectedElem.value) {
            elem.answerId = markedAnswers;
          }
        })
        return { ...prevState, submittedAnswerDets, details, status: PageStatus.Loaded }
      }, () => this.onCloseModalFunc())
    } catch (err) {
      this.setState({ status: PageStatus.Error, error: "Failed, Try again!" });
    }
  }

  addMoreOption = async (selectedElem) => {
    try {
      this.setState((prevVal)=>{
        let newObj = selectedElem.questionType != "Range" ? { "lable": "Answer", "name": selectedElem.questionType, "value": "" } : { "lable": "Select Value", "name": "Range", "fromValue": "", "toValue": "" };
        return {...prevVal, answersFields: [newObj], formType: "NEWOPTION"}
      });
    } catch (err) {
      this.setState({ status: PageStatus.Error, error: "Failed, Try again!" });
    }
  }

  // addMoreRow = (name, indx, type) => {
  //   if (type == "add") {
  //     if (!this.state.answersFields[indx].value && name != "Select Value") {
  //       alert("please enter answer.");
  //       return;
  //     }
  //     if ((!this.state.answersFields[indx].toValue || !this.state.answersFields[indx].fromValue) && name == "Select Value") {
  //       alert("please enter range values.");
  //       return;
  //     }
  //     let newObj = name != "Select Value" ? { "lable": name, "name": name, "value": "" } : { "lable": "Select Value", "name": "Range", "fromValue": "", "toValue": "" };
  //     this.setState((prevVal)=>{
  //       let updatedAnswers = [...prevVal.answersFields];
  //       updatedAnswers.push(newObj);
  //       return {...prevVal, answersFields: updatedAnswers}
  //     }) 
  //   } else {
  //     this.setState((prevVal)=>{
  //       let updatedAnswers = [...prevVal.answersFields];
  //       let newArr = updatedAnswers.filter((e, i) => i !== indx);
  //       return {...prevVal, answersFields: newArr}
  //     }) 
  //   }
  // }

  setInputValueInArr = (lable, evnt, indx, field) => {
    this.setState((prevVal) => {
      let updatedAnswers = [...prevVal.answersFields];
      let newArr = updatedAnswers.map((e, i) => {
          if (i == indx) {
            return { ...e, [field]: evnt.target.value };
          } else {
            return e;
          }
        });
      return {...prevVal, answersFields: newArr}
    });
  };

  checkValidationNumber = (evnt, indxx, fieldName)=>{
    const previouesAnsFields = this.state.selectedPreScreenQueDet.answers;
    const {answersFields} = this.state;
    this.setState((prevVal)=>{
      let updatedAnswers = [...prevVal.answersFields];
      if ((answersFields[indxx].fromValue != "") && (answersFields[indxx].toValue != "") && (parseInt(answersFields[indxx].fromValue) >= parseInt(answersFields[indxx].toValue))) {
        alert("'From' value can't be equal or greater than 'To' value.");
        updatedAnswers[indxx][fieldName] = "";
      }
      if((answersFields[indxx][fieldName] != "")){
          for (let elemIndxx = 0; elemIndxx < previouesAnsFields.length; elemIndxx++) {
            if(( parseInt(answersFields[indxx][fieldName]) <= parseInt(previouesAnsFields[elemIndxx].toValue) && parseInt(answersFields[indxx][fieldName]) >= parseInt(previouesAnsFields[elemIndxx].fromValue))){
              // alert(`${updatedAnswers[indxx][fieldName]} is included in row number ${elemIndxx+1}.`);
              alert(`${updatedAnswers[indxx][fieldName]} is included in previous options.`);
              updatedAnswers[indxx][fieldName] = "";
              break;
            }
            if((answersFields[indxx].fromValue != "") && (answersFields[indxx].toValue != "") && (( parseInt(answersFields[indxx].fromValue) < parseInt(previouesAnsFields[elemIndxx].toValue)) && (parseInt(answersFields[indxx].fromValue) < parseInt(previouesAnsFields[elemIndxx].fromValue))) && (( parseInt(answersFields[indxx].toValue) > parseInt(previouesAnsFields[elemIndxx].toValue)) && (parseInt(answersFields[indxx].toValue) > parseInt(previouesAnsFields[elemIndxx].fromValue)))){
              // alert(`Row number ${elemIndxx+1} is included in current range.`);
              alert(`Previous options are  included in current range.`);
              updatedAnswers[indxx][fieldName] = "";
              break;
            }
        }
      }
      return {...prevVal, answersFields: updatedAnswers};
    })
  }

  addNewOptionToSelectedQue = ()=>{
    if(this.state.selectedPreScreenQueDet.questionType != "Range"){
      if(this.state.answersFields[0].value == ""){
        alert("Fill option value first");
        return;
      }
    }else{
      if(this.state.answersFields[0].fromValue == "" || this.state.answersFields[0].toValue == ""){
        alert(`${this.state.answersFields[0].fromValue == "" ? "Fill 'From' value" : "Fill 'To' value"}`);
        return;
      }
    }
    this.setState((prevData)=>{
      let newDummyArr = prevData.selectedPreScreenQues;
        newDummyArr.forEach((elem)=>{
          if(elem.value == this.state.selectedPreScreenQueDet.value){
            let blockArr = this.state.selectedPreScreenQueDet.questionType != "Range" ? this.state.answersFields.map(ele=> ele.value) : this.state.answersFields;
            elem.answers = [...elem.answers, ...blockArr ];
          }
        });
      return {...prevData, selectedPreScreenQues: newDummyArr, formType: "UPDATE"}
    });
  }

  handleAnswerChange = (event, ansVal, idValue, type) => {
    let newArp = this.state.selectedPreScreenQues;
    newArp.forEach((ele) => {
      if (ele.value == idValue) {
        if (type == "checkbox") {
          let newArr = event.target.checked ? [...ele.selectedAnsArr, {"answer":ansVal, "mark":"y","questionQuotaPoints":0}] : [...ele.selectedAnsArr].filter(e => e.answer != ansVal);
          ele.selectedAnsArr = newArr;
        } else if (type == "Range") {
          let newArr = event.target.checked ? [...ele.selectedAnsArr, {"answer":ansVal, "mark":"y","questionQuotaPoints":0}] : [...ele.selectedAnsArr].filter(e => e.answer.fromValue != ansVal.fromValue && e.answer.toValue != ansVal.toValue);
          ele.selectedAnsArr = newArr;
        } else {
          let newArr = [...ele.selectedAnsArr, {"answer":ansVal, "mark":"y","questionQuotaPoints":0}].filter(e => e.answer == ansVal && e.answer != "");
          ele.selectedAnsArr = newArr;
        }
      }
    })
    this.setState((prevState) => {
      return { ...prevState, selectedPreScreenQues: newArp }
    });
  };

  addQuotaPointsToAns = (val, indxx)=>{
    let newNumber = this.state.selectedPreScreenQueDet.questionType !== "Range" ? 
      ( this.state.selectedPreScreenQueDet?.selectedAnsArr?.find((ele) => ele.answer == val)?.questionQuotaPoints ) : 
      ( this.state.selectedPreScreenQueDet?.selectedAnsArr?.find((ele) => ele.answer.fromValue == val.fromValue && ele.answer.toValue == val.toValue)?.questionQuotaPoints );
    this.setState({formType: "ADDQUOTAPOINTS", quotaPointAnswer: val, questionQuotaPoints: newNumber});
  }

  addQuotaPointsToPreScreenQue = ()=>{
    if(this.state.questionQuotaPoints != ""){
      this.setState((prevVal)=>{
        let newObj = this.state.selectedPreScreenQueDet;
        newObj.selectedAnsArr.forEach((ele)=>{
          if(newObj.questionType != "Range"){
            if(ele.answer == this.state.quotaPointAnswer){
              ele.questionQuotaPoints = this.state.questionQuotaPoints;
            }
          }else{
            if(parseInt(ele.answer.fromValue) == this.state.quotaPointAnswer.fromValue && parseInt(ele.answer.toValue) == this.state.quotaPointAnswer.toValue){
              ele.questionQuotaPoints = this.state.questionQuotaPoints;
            }
          }
        })
        return {...prevVal, selectedPreScreenQueDet: newObj, quotaPointAnswer: "", questionQuotaPoints: 0, formType: "UPDATE"}
      })
    }else{ alert("Please enter valid value"); }
  }

  setQuotaPointsToPreScreenQue = (event)=>{
    this.setState({ questionQuotaPoints: event.target.value });
  }

  calculateCompletePoints = (e) => {
    const loiValue = parseInt(e.target.value, 10);
    if (loiValue > 0 && loiValue <= 5) { this.setState({ overquota: 0, terminate: 0, ceggPoints: 15 }); }
    else if (loiValue >= 6 && loiValue <= 10) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 25 }); }
    else if (loiValue >= 11 && loiValue <= 15) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 35 }); }
    else if (loiValue >= 16 && loiValue <= 20) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 45 }); }
    else if (loiValue >= 21 && loiValue <= 25) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 55 }); }
    else if (loiValue >= 26 && loiValue <= 30) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 65 }); }
    else if (loiValue > 30) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 100 }); }
    else { this.setState({ overquota: 0, terminate: 0, ceggPoints: 0 }); }
  }

  // ==============================================================
  render() {
    const isChecked = (name: any) => this.state[name] === "Y";
    const surveyDescriptionOption = ["Education", "Fashion and Accessories", "Financial Services", "Food", "Healthcare", "Gadgets", "Home Care", "Lifestyles and Hobbies", "Media and Entertainment", "Corporate Related", "Personal Care and Beauty", "Pet Care", "Shopping and Retail", "Technology and Computing", "Toys and Games", "Travel", "Exciting New", "Augment Survey"];
    const {answersFields} = this.state;
    return (
      <Modal
        centered
        size="xl"
        backdrop="static"
        onHide={this.props.onClose}
        show={this.props.show}
        style={{ zIndex: 1201 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Survey
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
          <Show when={this.state.status === PageStatus.Loading}>
            <div className="d-flex justify-content-center w-100 p-5">
              <Spinner animation="border" variant="primary" />
            </div>
          </Show>

          <Alert variant="danger" show={this.state.status === PageStatus.Error}>
            {this.state.error}
          </Alert>

          <form onSubmit={this.props.handleSubmit(
            (event) => this.onSubmit(),
          )}
          >

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="name">Survey Name*</label>
                <input
                  className="form-control"
                  name="name"
                  onChange={(e) => this.setState({ name: e.target.value })}
                  value={this.state.name}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="company">Company*</label>
                <input
                  className="form-control"
                  id="company"
                  name="company"
                  onChange={(e) => this.setState({ company: e.target.value })}
                  value={this.state.company}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col'>
                <div className="custom-control custom-control-inline">
                  <input
                    type="checkbox"
                    id="validateStartEndIp"
                    name="validateStartEndIp"
                    checked={this.state.validateStartEndIp == "Y"}
                    className="custom-control-input"
                    onChange={this.handleCheckboxValidation}
                  />
                  <label className="custom-control-label" htmlFor="validateStartEndIp">Validate Start-End IP</label>
                </div>
                <div className="custom-control custom-control-inline">
                  <input
                    type="checkbox"
                    id="validateCountry"
                    name="validateCountry"
                    checked={this.state.validateCountry == "Y"}
                    className="custom-control-input"
                    onChange={this.handleCheckboxValidation}
                  />
                  <label className="custom-control-label" htmlFor="validateCountry">Validate Country</label>
                </div>
              </div>
              <div className='col'>
                <div className="custom-control custom-control-inline">
                  <input
                    type="checkbox"
                    id="prescreen"
                    name="preQuestionsYN"
                    checked={isChecked("preQuestionsYN")}
                    className="custom-control-input"
                    onChange={this.handleChangePrescreen}
                  />
                  <label className="custom-control-label" htmlFor="prescreen">Add pre screening questions</label>
                </div>
              </div>
            </div>

            <div className='row mt-2' id='idPrescreenDiv'>
              <div className='col' style={{ zIndex: "1200" }}>
                <label htmlFor="text">Pre Screen Questions</label>
                <Select
                  name='preques'
                  id='preques'
                  onChange={this.handlePreScreenQuestions}
                  value={this.state.selectedPreScreenQues}
                  isMulti
                  required
                  isDisabled={this.state["preQuestionsYN"] === "Y" ? false : true}
                  options={this.state.preScreenOptions}
                />
              </div>
            </div>

            <Show when={this.state.selectedPreScreenQues.length > 0}>
              <div className="row mt-3">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <h4>Selected Questions:</h4>
                  </div>
                  <Table responsive size="sm" bordered>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Question</th>
                        <th>Selected Answers</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        this.state.selectedPreScreenQues?.map((info, index) => (
                          <tr key={info.value}>
                            <td>{index + 1}</td>
                            <td>{info.label ?? "-"}</td>
                            <td>
                              {
                                this.state.submittedAnswerDets?.includes(info.value) ?
                                  info.questionType === "Range" ?
                                  info.selectedAnsArr?.map(e => `${e.answer.fromValue} - ${e.answer.toValue}${e.questionQuotaPoints > 0 ? ` (${e.questionQuotaPoints})` : ""}`).join(", ") :
                                  info.selectedAnsArr?.map((el)=> { return el.answer + (el.questionQuotaPoints > 0 ? ` (${el.questionQuotaPoints})` : "") }).join(", ")
                                  : "-"
                              }
                            </td>
                            <td>
                              {/* <Confirmation onAction={() => this.removeItemFromTableFunc(info.id, info.type)} body="Do you want to delete item ?"> */}
                              <Button
                                variant={this.state.submittedAnswerDets?.includes(info.value) ? "success" : "warning"}
                                size="sm"
                                className="mx-1"
                                onClick={() => this.updatePreScreenAnswer(info, index)}
                              >

                                {this.state.submittedAnswerDets?.includes(info.value) ? "Update Answer" : "Select Answer"}
                              </Button>
                              {/* </Confirmation> */}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </Show>

            <Modal
              centered
              size="lg"
              backdrop="static"
              onHide={this.onCloseAddAnsModalFunc}
              show={this.state.formType == "NEWOPTION"}
              style={{ zIndex: 1201 }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add New Option</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
                <Show when={this.state.status === PageStatus.Loading}>
                  <div className="d-flex justify-content-center w-100 p-5">
                    <Spinner animation="border" variant="primary" />
                  </div>
                </Show>
                <Alert variant="danger" show={this.state.status === PageStatus.Error}>  {this.state.error}  </Alert>
                <Show when={this.state.status != PageStatus.Loading}>
                  <div>
                    <div className="row mt-2">
                    {answersFields && answersFields.map((ele, index) => (
                      ele.name == "Range" ? (<div className="col p-3" key={`abcd${index}`}>
                          <label htmlFor="body"></label>
                          <div className="input-group">
                              <input
                                className="form-control mx-1"
                                name={ele.name}
                                onChange={(e) => this.setInputValueInArr(ele.lable, e, index, "fromValue")}
                                onBlur={(e)=> this.checkValidationNumber(e, index, "fromValue")}
                                value={ele.fromValue}
                                type='number'
                                placeholder={`From`}
                                required
                              />
                              <input
                                className="form-control mx-1"
                                name={ele.name}
                                onChange={(e) => this.setInputValueInArr(ele.lable, e, index, "toValue")}
                                onBlur={(e)=> this.checkValidationNumber(e, index, "toValue")}
                                value={ele.toValue}
                                type='number'
                                placeholder={`To`}
                                required
                              />
                              {/* {answersFields.length > 0 && index == answersFields.length-1 ? (
                                <em
                                onClick={(e) => this.addMoreRow(ele.lable, index, "add")}
                                className="fa fa-plus text-success fa-lg cursor-pointer input-group-text"
                                title="Add more"
                                />
                              ) : null}
                              {answersFields.length > 1 ? (
                                <em
                                  onClick={() => this.addMoreRow(ele.lable, index, "dlt")}
                                  className="fa fa-trash text-danger fa-lg cursor-pointer input-group-text"
                                  title="Delete row"
                                />
                              ) : null} */}
                          </div>
                        {/* </div> */}
                      </div>) :
                        (
                          <div className="col p-3" key={`abcd${index}`}>
                            <label htmlFor="body">Answer {answersFields.length > 1 ? index + 1 : ""}*</label>
                            <div className="input-group">
                              <input
                                className="form-control"
                                type="text"
                                value={ele.value}
                                name=""
                                id=""
                                onChange={(e) => this.setInputValueInArr(ele.lable, e, index, "value")}
                                placeholder={`Enter answer ${answersFields.length > 1 ? index + 1 : ""}`}
                                required
                              />
                              {/* {answersFields.length > 0 && index == answersFields.length-1 ? (
                              <em
                                onClick={(e) => this.addMoreRow(ele.lable, index, "add")}
                                className="fa fa-plus text-success fa-lg cursor-pointer input-group-text"
                                title="Add more"
                              />
                              ) : null}
                              {answersFields.length > 1 ? (
                                <em
                                  onClick={() => this.addMoreRow(ele.lable, index, "dlt")}
                                  className="fa fa-trash text-danger fa-lg cursor-pointer input-group-text"
                                  title="Delete row"
                                />
                              ) : null} */}
                            </div>

                          </div>
                        )
                    ))}
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <button onClick={() => this.addNewOptionToSelectedQue()} className="btn btn-primary">
                        Add
                      </button>
                      {/* {this.state.status === PageStatus.Submitting && (
                        <Spinner animation="border" variant="primary" />
                      )} */}
                    </div>
                  </div>
                </Show>
              </Modal.Body>
            </Modal>

            <Modal
              centered
              size="lg"
              backdrop="static"
              onHide={this.onCloseAddAnsModalFunc}
              show={this.state.formType == "ADDQUOTAPOINTS"}
              style={{ zIndex: 1201 }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Quota</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
                <Show when={this.state.status === PageStatus.Loading}>
                  <div className="d-flex justify-content-center w-100 p-5">
                    <Spinner animation="border" variant="primary" />
                  </div>
                </Show>
                <Alert variant="danger" show={this.state.status === PageStatus.Error}>  {this.state.error}  </Alert>
                <Show when={this.state.status != PageStatus.Loading}>
                  <div>
                    <div className="row mt-2">
                      <div className="col">
                        <label htmlFor="quotaPoints">Enter Quota</label>
                        <input
                          className="form-control mx-1"
                          name="quotaPoints"
                          onChange={(e) => this.setQuotaPointsToPreScreenQue(e)}
                          value = { this.state.questionQuotaPoints }
                          type='number'
                          placeholder="Enter quota"
                          required
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <button onClick={() => this.addQuotaPointsToPreScreenQue()} className="btn btn-primary">
                        Add
                      </button>
                    </div>
                  </div>
                </Show>
              </Modal.Body>
            </Modal>

            <Modal
              centered
              size="lg"
              backdrop="static"
              onHide={this.onCloseModalFunc}
              show={this.state.formType == "UPDATE"}
              style={{ zIndex: 1201 }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Answer </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
                <Show when={this.state.status === PageStatus.Loading}>
                  <div className="d-flex justify-content-center w-100 p-5">
                    <Spinner animation="border" variant="primary" />
                  </div>
                </Show>
                <Alert variant="danger" show={this.state.status === PageStatus.Error}>  {this.state.error}  </Alert>
                <Show when={this.state.status != PageStatus.Loading}>
                  {this.state.selectedPreScreenQueDet?.label && (
                    // <form onSubmit={(event)=>this.onFormUpdate(event)}>
                    <div>
                      <div className="row mt-2">
                        <div className="col">
                          <label>{this.state.selectedPreScreenQueDet?.label}</label>
                          {this.state.selectedPreScreenQueDet?.answers?.map((e, index) => (
                            // console.log("e = ", this.state.selectedPreScreenQueDet.questionType) &&
                            // this.state.selectedPreScreenQueDet?.questionType === "Radio" ? (
                            //   <div className="custom-control custom-radio" key={index}>
                            //   <input
                            //     type="radio"
                            //     id={`prescreen-${index}`}
                            //     name={`prescreen-${index}`}
                            //     checked={this.state.selectedPreScreenQueDet?.selectedAnsArr?.includes(e)}
                            //     className="custom-control-input"
                            //     onChange={(evt) => this.handleAnswerChange(evt, e, this.state.selectedPreScreenQueDet?.value, "radio")}
                            //   />
                            //   <label className="custom-control-label" htmlFor={`prescreen-${index}`}><span className='text-lg'>{e}</span></label>
                            // </div>
                            // ) : (this.state.selectedPreScreenQueDet?.questionType === "Checkbox" || this.state.selectedPreScreenQueDet?.questionType === "checkbox") ? (
                            this.state.selectedPreScreenQueDet.questionType != "Range" ? (
                              <div className="col ml-2" key={index}>
                                <input
                                  type="checkbox"
                                  id={`prescreen-${index}`}
                                  name={`prescreen-${index}`}
                                  checked={this.state.selectedPreScreenQueDet?.selectedAnsArr?.some(e1 => e1.answer === e && e1.mark === "y")}
                                  // checked={this.state.selectedPreScreenQueDet?.selectedAnsArr?.includes(e)}
                                  className="custom-control-input"
                                  onChange={(evt) => this.handleAnswerChange(evt, e, this.state.selectedPreScreenQueDet?.value, "checkbox")}
                                />
                                <label className="custom-control-label" htmlFor={`prescreen-${index}`}><span className='text-lg'>{e}</span></label>
                                {( this.state.selectedPreScreenQueDet?.selectedAnsArr?.some(e1 => e1.answer === e && e1.mark === "y") ?
                                  <span className='ml-2'>
                                  <em
                                    onClick={(evt) => this.addQuotaPointsToAns(e, index)}
                                    // className="fa fa-plus text-success cursor-pointer custom-control-icon"
                                    className={`fa fa-plus text-${parseInt(this.state.selectedPreScreenQueDet.selectedAnsArr.filter(ele=> ele.answer == e)[0].questionQuotaPoints) > 0 ? "success" : "warning"} cursor-pointer custom-control-icon`}
                                    title="Add quota"
                                  />
                                </span> : "")}
                              </div>) : (<div className="col ml-2" key={index}>
                                <input
                                  type="checkbox"
                                  id={`prescreen-${index}`}
                                  name={`prescreen-${index}`}
                                  checked={this.state.selectedPreScreenQueDet?.selectedAnsArr?.some((someVal) => someVal.answer.fromValue == e.fromValue && someVal.answer.toValue == e.toValue)}
                                  className="custom-control-input"
                                  onChange={(evt) => this.handleAnswerChange(evt, e, this.state.selectedPreScreenQueDet?.value, "Range")}
                                />
                                <label className="custom-control-label" htmlFor={`prescreen-${index}`}><span className='text-lg'>{e.fromValue}-{e.toValue}</span></label>
                                {(this.state.selectedPreScreenQueDet?.selectedAnsArr?.some((someVal) => someVal.answer.fromValue == e.fromValue && someVal.answer.toValue == e.toValue) ?
                                  <span className='ml-2'>
                                  <em
                                    onClick={(evt) => this.addQuotaPointsToAns(e, index)}
                                    className={`fa fa-plus text-${parseInt(this.state.selectedPreScreenQueDet.selectedAnsArr.filter((someVal) => someVal.answer.fromValue == e.fromValue && someVal.answer.toValue == e.toValue)[0].questionQuotaPoints) > 0 ? "success" : "warning"} cursor-pointer custom-control-icon`}
                                    // className={`fa fa-plus text-${this.state.selectedPreScreenQueDet.questionQuotaPoints > 0 ? "suceess" : "warning"} cursor-pointer custom-control-icon`}
                                    title="Add quota"
                                  />
                                </span> : "")}
                              </div>
                            )
                            // ) : null
                          ))}

                          {/* {
                       (this.state.selectedPreScreenQueDet?.questionType == "Range" || this.state.selectedPreScreenQueDet?.questionType == "Zone" || this.state.selectedPreScreenQueDet?.questionType == "Zipcode" || this.state.selectedPreScreenQueDet?.questionType == "Dropdown") && <div>
                        <select 
                          name="rangeSelect" 
                          id="rangeSelect" 
                          className='form-control'
                          // value={(this.state.selectedAnswer.length > 0 ? this.state.selectedAnswer[0] : "")}
                          onChange={(evt) => this.handleAnswerChange(evt, evt.target.value, this.state.selectedPreScreenQueDet?.value, "Range")}
                          >
                          <option value="">Select option</option>
                          {this.state.selectedPreScreenQueDet?.answers?.map((e, index) => (
                            <option selected={this.state.selectedPreScreenQueDet?.selectedAnsArr?.includes(e)} value={e} key={index}>{e}</option>
                          ))}
                        </select>
                       </div>
                      } */}
                        </div>
                      </div>

                      {/* Error message */}
                      <Alert variant="danger" show={this.state.status === PageStatus.Error} className="mt-2">
                        {this.state.error}
                      </Alert>

                      <div className="d-flex align-items-center mt-2">
                        <button onClick={() => this.addMoreOption(this.state.selectedPreScreenQueDet)} className="btn btn-success mr-3">
                          Add New Option
                        </button>
                        <button onClick={() => this.onFormUpdate(this.state.selectedPreScreenQueDet)} className="btn btn-primary mr-3">
                          Submit
                        </button>

                        {/* Show spinner when submitting */}
                        {this.state.status === PageStatus.Submitting && (
                          <Spinner animation="border" variant="primary" />
                        )}
                      </div>
                    </div>
                    // </form>
                  )}
                </Show>

              </Modal.Body>
            </Modal>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Description*</label>
                {/* <input
                      className="form-control"
                      name="description"
                      onChange={(e) => this.setState({ description: e.target.value })}
                      value={this.state.description}
                      placeholder="Enter here"
                      required
                  /> */}
                <select
                  name='description'
                  id='description'
                  onChange={(e) => { this.setState({ description: e.target.value }) }}
                  value={this.state.description}
                  required
                  style={{
                    width: '100%',
                    display: 'block',
                    height: '40px',
                    lineHeight: '1.5',
                    color: '#495057',
                    backgroundColor: '#fff',
                    backgroundClip: 'padding-box',
                    border: '1px solid #ced4da',
                    borderRadius: '5px',
                    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                  }}
                >
                  <option value=''>--Choose--</option>
                  {surveyDescriptionOption.length > 0 ? surveyDescriptionOption.map((item, index) => (
                    <option key={index} value={"Survey On " + item}>{item}</option>
                  )) : ''}
                </select>
              </div>
              <div className="col">
                <label htmlFor="company">PM Name*</label>
                <input
                  className="form-control"
                  id="pmName"
                  name="pmName"
                  onChange={(e) => {(/^[A-Za-z\s]+$/).test(e.target.value) || e.target.value == "" ? this.setState({ pmName: e.target.value }) : null}}
                  value={this.state.pmName}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col mt-2">
                <label htmlFor="description">Survey Type*</label><br />
                {/* <div className="custom-control custom-radio custom-control-inline">
                    <input
                        type="radio"
                        id="surveyType1"
                        name="surveyType1"
                        className="custom-control-input"
                        checked={this.state.surveyType === 'Open'}
                        onChange={() => this.setState({ surveyType: 'Open' })}
                    />
                    <label className="custom-control-label" htmlFor="surveyType1" >Open</label>
                  </div> */}

                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="surveyType3"
                    name="surveyType3"
                    checked={this.state.surveyType === 'Hold'}
                    className="custom-control-input"
                    onChange={() => this.setState({ surveyType: 'Hold' })}
                  />
                  <label className="custom-control-label" htmlFor="surveyType3">On Hold</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="surveyType1"
                    name="surveyType1"
                    className="custom-control-input"
                    checked={this.state.surveyType === 'Open'}
                    onChange={() => this.setState({ surveyType: 'Open' })}
                    onClick={() => this.handleClick('Open')}
                  />
                  <label className="custom-control-label" htmlFor="surveyType1">Live</label>
                </div>

                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="surveyType4"
                    name="surveyType4"
                    checked={this.state.surveyType === 'Wait'}
                    className="custom-control-input"
                    onChange={() => this.setState({ surveyType: 'Wait' })}
                  />
                  <label className="custom-control-label" htmlFor="surveyType4">Ids awaited </label>
                </div>



                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="surveyType2"
                    name="surveyType1"
                    checked={this.state.surveyType === 'Close'}
                    className="custom-control-input"
                    onChange={() => this.setState({ surveyType: 'Close' })}
                  />
                  <label className="custom-control-label" htmlFor="surveyType2">End</label>
                </div>
              </div>

              <div className="col mt-2">
                <label htmlFor="description">Point Allocation*</label><br />
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="pointAllocation1"
                    name="pointAllocation1"
                    className="custom-control-input"
                    checked={this.state.pointAllocationType === 'Manual'}
                    onChange={() => this.setState({ pointAllocationType: 'Manual' })}
                  />
                  <label className="custom-control-label" htmlFor="pointAllocation1" >Manual</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="pointAllocation2"
                    name="pointAllocation1"
                    checked={this.state.pointAllocationType === 'Auto'}
                    className="custom-control-input"
                    onChange={() => this.setState({ pointAllocationType: 'Auto' })}
                  />
                  <label className="custom-control-label" htmlFor="pointAllocation2">Auto</label>
                </div>
              </div>


              {/* ------------------------- */}
              <div className="col mt-2">
                <label htmlFor="userLimitCommitted_checkbox">IPolls Quota </label>
                <div className="col">
                  <input
                    type="checkbox"
                    id='userLimitCommitted_checkbox'
                    name="userLimitCommitted_checkbox"
                    onChange={this.handleUserLimitCommittedChange}
                    checked={this.state.userLimitCommitted_checkbox || this.state.IPollsQuotaLimit == "Y"} // Use checked instead of value

                  />
                </div>
              </div>
              {/* ------------------------- */}
              <div className="col mt-2">
                <label htmlFor="description">Send Survey*</label><br />
                <div className="custom-control custom-control-inline">
                  <input
                    type="checkbox"
                    id="email"
                    name="email"
                    className="custom-control-input"
                    checked={isChecked("email")}
                    onChange={this.handleSendSurvey}
                  />
                  <label className="custom-control-label" htmlFor="email" >Email</label>
                </div>
                <div className="custom-control custom-control-inline">
                  <input
                    type="checkbox"
                    id="sms"
                    name="sms"
                    checked={isChecked("sms")}
                    className="custom-control-input"
                    onChange={this.handleSendSurvey}
                  />
                  <label className="custom-control-label" htmlFor="sms">SMS</label>
                </div>
                <div className="custom-control custom-control-inline">
                  <input
                    type="checkbox"
                    id="whatsapp"
                    name="whatsapp"
                    checked={isChecked("whatsapp")}
                    className="custom-control-input"
                    onChange={this.handleSendSurvey}
                  />
                  <label className="custom-control-label" htmlFor="whatsapp">Whats App</label>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="userLimitCommitted">Client Quota *</label>
                <input
                  type="number"
                  className="form-control"
                  name="userLimitCommitted"
                  onChange={(e) => this.setState({ userLimitCommitted: e.target.value })}
                  value={this.state.userLimitCommitted}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="userLimitCutoff">IPolls Quota *</label>
                <input
                  type="number"
                  className="form-control"
                  id="userLimitCutoff"
                  name="userLimitCutoff"
                  onChange={(e) => this.setState({ userLimitCutoff: e.target.value })}
                  value={this.state.userLimitCutoff}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="surveyLength">Survey Length(in minutes)*</label>
                <input
                  type="number"
                  className="form-control"
                  id="surveyLength"
                  name="surveyLength"
                  onChange={(e) => this.setState({ surveyLength: e.target.value }, () => { this.calculateCompletePoints(e) })}
                  value={this.state.surveyLength}
                  placeholder="Enter here"
                  required
                />
              </div>
              {/* <div className="col">
                <label htmlFor="client">Client*</label>
                <input
                  className="form-control"
                  name="client"
                  onChange={(e) => this.setState({ client: e.target.value })}
                  value={this.state.client}
                  placeholder="Enter here"
                  required
                />
              </div> */}
              <div className="col">
                <label htmlFor="ceggPoints">Complete Points*</label>
                <input
                  type="number"
                  className="form-control"
                  id="ceggPoints"
                  name="ceggPoints"
                  onChange={(e) => { this.setState({ ceggPoints: e.target.value }, this.calculateIr); }}
                  value={this.state.ceggPoints}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="overquota">Over Quota Points*</label>
                <input
                  type="number"
                  className="form-control"
                  id="overquota"
                  name="overquota"
                  onChange={(e) => this.setState({ overquota: e.target.value })}
                  value={this.state.overquota}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="ceggPoints">Terminate Points*</label>
                <input
                  type="number"
                  className="form-control"
                  id="terminate"
                  name="terminate"
                  onChange={(e) => { this.setState({ terminate: e.target.value }, this.calculateIr); }}
                  value={this.state.terminate}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="qualityterminate">IR*</label>
                <input
                  type="number"
                  className="form-control"
                  id="clientir"
                  name="clientir"
                  onChange={(e) => this.setState({ clientir: e.target.value })}
                  value={this.state.clientir}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="qualityterminate">Quality Terminate Points*</label>
                <input
                  type="number"
                  className="form-control"
                  id="qualityterminate"
                  name="qualityterminate"
                  onChange={(e) => this.setState({ qualityterminate: e.target.value })}
                  value={this.state.qualityterminate}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>


            <div className="row mt-2">
              <div className="col">
                <label htmlFor="companyLogo">Logo</label>
                <input
                  className="form-control"
                  name="companyLogo"
                  onChange={(e) => this.setState({ companyLogo: e.target.value })}
                  value={this.state.companyLogo}
                  placeholder="Enter here"
                />
              </div>
              {/* <div className="col">
                <label htmlFor="surveyLength">Survey Length(in minutes)*</label>
                <input
                  type="number"
                  className="form-control"
                  id="surveyLength"
                  name="surveyLength"
                  onChange={(e) => this.setState({ surveyLength: e.target.value }, ()=>{this.calculateCompletePoints(e)})}
                  value={this.state.surveyLength}
                  placeholder="Enter here"
                  required
                />
              </div> */}
              <div className="col">
                <label htmlFor="client">Client*</label>
                <input
                  className="form-control"
                  name="client"
                  onChange={(e) => this.setState({ client: e.target.value })}
                  value={this.state.client}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="text">Start Date*</label>
                <input
                  type="date"
                  className="form-control"
                  name="publishDate"
                  onChange={(e) => this.setState({ publishDate: e.target.value })}
                  value={this.state.publishDate}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="expiryDate">End Date*</label>
                <input
                  type="date"
                  className="form-control"
                  id="expiryDate"
                  name="expiryDate"
                  onInput={this.check_date_past}
                  onChange={(e) => this.setState({ expiryDate: e.target.value })}
                  value={this.state.expiryDate}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="costPerInterview">Cost Per Interview (INR)*</label>
                <input
                  type="number"
                  className="form-control"
                  name="costPerInterview"
                  onChange={(e) => this.setState({ costPerInterview: e.target.value })}
                  value={this.state.costPerInterview}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="outlierCutoffTime">Speeder Limit (In minutes)*</label>
                <input
                  type="number"
                  className="form-control"
                  id="outlierCutoffTime"
                  name="outlierCutoffTime"
                  onChange={(e) => this.setState({ outlierCutoffTime: e.target.value })}
                  value={this.state.outlierCutoffTime}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="text">BlackList Surveys</label>
                <Select
                  name='state'
                  id='state'
                  onChange={this.handleChange}
                  value={this.state.selectedSurveyOptionss}
                  isMulti
                  required
                  options={this.state.options}
                />
                {/* <Select
                  name='state'
                  id='state'
                  onChange={this.handleChange}
                  value={this.state.selectedSurveyOptions}
                  required
                  options={this.state.options}
                /> */}
              </div>
              <div className="col">
                <label htmlFor="surveyUrlIdentifier">Survey Url Identifier*</label>
                <input
                  className="form-control"
                  id="surveyUrlIdentifier"
                  name="surveyUrlIdentifier"
                  onChange={(e) => this.setState({ surveyUrlIdentifier: e.target.value })}
                  value={this.state.surveyUrlIdentifier}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>

            <div className="row ml-3 mt-2">
              <div className="col">
                <input
                  type="checkbox"
                  id="ipUnique"
                  name="ipUnique"
                  className="custom-control-input"
                  checked={this.state.ipUnique}
                  onChange={() => this.setState({ ipUnique: !this.state.ipUnique })}
                />
                <label className="custom-control-label" htmlFor="ipUnique" >Use Unique IPs</label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  id="useUniqueLinks"
                  name="useUniqueLinks"
                  className="custom-control-input"
                  checked={this.state.useUniqueLinks}
                  onChange={() => this.setState({ useUniqueLinks: !this.state.useUniqueLinks })}
                />
                <label className="custom-control-label" htmlFor="useUniqueLinks">Use Unique Links</label>
              </div>
            </div>

            <Show when={this.state.useUniqueLinks === false}>
              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="url">Survey URL*</label>
                  <input
                    className="form-control"
                    name="url"
                    onChange={(e) => this.setState({ url: e.target.value })}
                    value={this.state.url}
                    placeholder="Enter here"
                    required
                  />
                </div>
              </div>
            </Show>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="url">Disclaimer</label>
                <textarea
                  className="form-control"
                  style={{ height: '180px' }}
                  onChange={(e) => this.setState({ disclaimer: e.target.value })}
                  value={this.state.disclaimer}
                  placeholder="Please enter"
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label htmlFor='country'>Country*</label>
                <Select
                  name='countryTitle'
                  id='countryTitle'
                  onChange={this.handleCountryChange}
                  value={this.state.selectedCountryOption}
                  required
                  options={this.state.countries}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Description One</label>
                <input
                  className="form-control"
                  name="description"
                  onChange={(e) => this.setState({ description_one: e.target.value })}
                  value={this.state.description_one}
                  placeholder="Enter here"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Description Two</label>
                <input
                  className="form-control"
                  name="description"
                  onChange={(e) => this.setState({ description_two: e.target.value })}
                  value={this.state.description_two}
                  placeholder="Enter here"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Description Three</label>
                <input
                  className="form-control"
                  name="description"
                  onChange={(e) => this.setState({ description_three: e.target.value })}
                  value={this.state.description_three}
                  placeholder="Enter here"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Description Four</label>
                <input
                  className="form-control"
                  name="description"
                  onChange={(e) => this.setState({ description_four: e.target.value })}
                  value={this.state.description_four}
                  placeholder="Enter here"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Color Code</label>
                <input
                  className="form-control"
                  name="description"
                  onChange={(e) => this.setState({ colorCode: e.target.value })}
                  value={this.state.colorCode}
                  placeholder="Enter here"
                />
              </div>
            </div>

            <hr />

            <Alert variant="danger" show={!!this.state.error} className="mt-2">
              {this.state.error}
            </Alert>

            <div className="d-flex align-items-center mt-2">
              <button
                type="submit"
                disabled={!this.state.name}
                className="btn btn-primary mr-3"
              >
                Submit
              </button>

              <button
                type="button"
                disabled={false}
                onClick={() => this.reset()}
                className="btn btn-light mr-3"
              >
                Reset
              </button>

              <Show when={this.state.status === PageStatus.Submitting}>
                <Spinner animation="border" variant="primary" />
              </Show>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const dataFormRedux = reduxForm < FormValue, any> ({
  form: 'dataForm',
})(Form);


const dataFormWithRouter = withRouter(dataFormRedux);

export { dataFormWithRouter as Form };
