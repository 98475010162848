import * as _ from 'lodash';

import { api } from '../axiosConfig';
import {whatsappTemp} from "../Utils/urls";

export class WhatsappTempAPI {
    static async create (
        data: any,
    ): Promise<any[]>{
        return await api.post(`${whatsappTemp}/create`, data).then((res) => {
            return _.get(resizeBy, 'data', []);
        });
    }

    static async getAll(
        limit : any,
        pagenumber: number,
        filters: any,
    ): Promise<any[]> {
        return await api.post(`${whatsappTemp}/getAll/${limit}/${pagenumber}`, filters).then((res) => {            
            return _.get(res, 'data', []);
        });
    }   
    
    static async update (
        id: any,
        data: any,
    ): Promise<any[]> {
        return await api.post(`${whatsappTemp}/updatedetails/${id}`, data).then((res)=>{
            return _.get(res, 'data', []);
        });
    }

    static async delete (
        id: string,
    ): Promise<any[]>{
        return await api.post(`${whatsappTemp}/delete/${id}`, {}).then((res)=>{
            return _.get(res, "data", []);
        });
    }

    static async getById (
        id: string,
    ): Promise<any[]>{
        return await api.post(`${whatsappTemp}/getById/${id}`, {}).then((res)=>{
            return _.get(res, "data", []);
        });
    }
}
